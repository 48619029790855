// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

//old version reads : import nekojs from './contracts/Nekoxs.json'

import mutantmoneysociety from './../contracts/Mutantmoneysociety.json'
import axios from 'axios';


import { RecoilRoot } from 'recoil'

import NavbarTwoCustom from './../components/_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
import ManualWhitelisting from './../components/Common/ManualWhitelisting';

import { ToastProvider } from 'react-toast-notifications';

//////////////////////////////////////////////////////////
///changed to page interacting with the smart contract
//////////////////////////////////////////////////////////

class ManualWhitelistPage extends React.Component {



  //mounting the main Element
  async componentDidMount() {
    //await this.loadWeb3(this.props.dispatch)

  }
  //Renaming function
  async werename(tokenId, newName) {

    if (this.state.contract !== 'undefined') {
      try {

        await this.state.contract.methods.changeName(tokenId, newName).send({ from: this.state.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }

  //network and web3 logic

  async loadWeb3(dispatch) {
    if (window.ethereum) {

      const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
      const netId = await web3.eth.net.getId()      // gets the value of the network
      const enabledWeb3 = await ethereum.enable();
      console.log("netId", netId)
      const accounts = await web3.eth.getAccounts()
      console.log("accounts[0]", accounts[0])



      //load balance//
      if (typeof accounts[0] !== 'undefined') {
        console.log("-----getting balance-----")
        const balance = await web3.eth.getBalance(accounts[0])
        console.log("-----balance-----")
        console.log(balance)
        console.log("balance", web3.utils.fromWei(balance, 'ether'), "ETH")

        this.setState({ account: accounts[0], balance: balance, web3: web3 })

      } else {
        window.alert('Please login with MetaMask')
      }


      //load contracts
      try {


        const contract = new web3.eth.Contract(mutantmoneysociety.abi, mutantmoneysociety.networks[netId].address)  // comes from ABI json file
        
        console.log("pisut2")
        console.log(contract)
        this.setState({ contract }) /// shortcut for {contract : contract}

        const totalSupply = await contract.methods.totalSupply().call()

        this.setState({ totalSupply })

        console.log("contract address", mutantmoneysociety.networks[netId].address)

        const contractBalance = await web3.eth.getBalance(mutantmoneysociety.networks[netId].address, function (err, result) {
          if (err) {
            console.log(err)
          } else {


            console.log("Balance of Contract:", web3.utils.fromWei(result, "ether") + " ETH")
          }
        })
        console.log("contract balance", contractBalance)

        this.setState({ contractBalance })

        //full sale status
        const FullSaleOK = await contract.methods.FullSaleOK().call()
        console.log("FullSaleOK", FullSaleOK)
        this.setState({ FullSaleOK })

        //full sale status
        const PreSaleOK = await contract.methods.PreSaleOK().call()
        console.log("PreSaleOK", PreSaleOK)
        this.setState({ PreSaleOK })

        //check whitelisting sale status
        const VIPWhiteListingOpen = await contract.methods.VIPWhiteListingOpen().call()
        console.log("VIPWhiteListingOpen", VIPWhiteListingOpen)
        this.setState({ VIPWhiteListingOpen })

        //get the current price
        const currentPrice = await contract.methods.MMSNFTPrice().call().catch(error => {
          return web3.utils.toWei('1000', 'ether')
        });
        console.log("currentPrice", web3.utils.fromWei(currentPrice, 'ether'), "ETH")


        console.log("total supply", this.props.totalSupply)
        console.log("getting the ownerAccount")

        //get the ownerAccount
        const owner = await contract.methods.owner().call()
        this.setState({ ownerAccount: owner })
        console.log("owner", owner)

        //getting the VIP price
        const mVIPAccessPrice = await contract.methods.VIPAccessPrice().call()
        console.log("VIPAccessPrice:", web3.utils.fromWei(mVIPAccessPrice, "ether") + " ETH")


        //getting the VIP whtelist quota
        const mVIP_WHITELISTING_SUPPLY = await contract.methods.VIP_WHITELISTING_SUPPLY().call()
        console.log("VIP_WHITELISTING_SUPPLY:", mVIP_WHITELISTING_SUPPLY)


        //checking the supplies at full sale
        const mMAX_NFT_SUPPLY_FULLSALE = await contract.methods.MAX_NFT_SUPPLY_FULLSALE().call()
        console.log("MAX_NFT_SUPPLY_FULLSALE:", mMAX_NFT_SUPPLY_FULLSALE)

        //checking the supplies at pre sale
        const mMAX_NFT_SUPPLY_PRESALE = await contract.methods.MAX_NFT_SUPPLY_PRESALE().call()
        console.log("MAX_NFT_SUPPLY_PRESALE:", mMAX_NFT_SUPPLY_PRESALE)



        //checking max purchase at pre-sale
        const mMAX_NFT_ATONCE_PRESALE = await contract.methods.MAX_NFT_ATONCE_PRESALE().call()
        console.log("MAX_NFT_ATONCE_PRESALE:", mMAX_NFT_ATONCE_PRESALE)

        //checking max purchase at full-sale
        const mMAX_NFT_ATONCE_FULLSALE = await contract.methods.MAX_NFT_ATONCE_FULLSALE().call()
        console.log("MAX_NFT_ATONCE_FULLSALE:", mMAX_NFT_ATONCE_FULLSALE)


        //get the current price
        const wlTracker = await contract.methods.WLtracker().call().catch(error => {

        });

        console.log('wlTracker: ', wlTracker)



        //get the current price
        const VIP_WHITELISTING_SUPPLY_counter = await contract.methods.VIP_WHITELISTING_SUPPLY_counter().call().catch(error => {

        });

        console.log('VIP registrations @ 0.011e : ', VIP_WHITELISTING_SUPPLY_counter)


        // get the URI of token8
        //checking max purchase at full-sale
//        const m8URI = await contract.methods.tokenURI(8).call()
//        console.log("m8URI:", m8URI)



        if (this.state.totalSupply > 0) {

          //getting the tokenURI
          const tokenURI = await contract.methods.tokenURI(0).call()
          console.log("Large supply now", this.state.totalSupply)
          console.log("tokenURI", tokenURI)


        }
        else {
          console.log("Supply is 0 for now")
        }


      } catch (e) {
        console.log('Error', e)
        window.alert('ERRORs -- Generic error in the web3')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }   // END OF LOADING block chain data


  constructor(props) {
    super(props);
    this.state = {
      account: '',
      contract: null,
      ownerAccount: null,
      totalSupply: 0,
      sizeToBuy: 0,
      currentPrice: 10,
      tokenIdsOwned: [],
      tokenIdsOwnedNames: [],
      nftowner: '',
      nftbal: 0,
      contractBalance: 0,
      date: new Date(),
      tokenURI: "",
      startingIndex: null,
      startingIndexBlock: null,
      FullSaleOK: false,
      PreSaleOK: false,
      whishslist:[],
      VIPWhiteListingOpen: true

    };

  }


  //addWhishslist = (whishs_list) => this.setState({ whishslist: [...this.state.whishslist, whishs_list]});

  //run it in a loop
  // componentDidMount() {
  //   this.timerID = setInterval(
  //           () => this.weinit(),
  //                10000    );
  //              }



  render() {
    return (
      <React.Fragment>
        <RecoilRoot>
          <NavbarTwoCustom />
          <div className="page-title-area">
              <div className="container">
                  <div className="page-title-content">
                  <h2>{"Manually"} <span style={{ color: '#F2356D' }}> Whitelist</span></h2>
                  </div>
              </div>
          </div>



          <ToastProvider autoDismiss autoDismissTimeout={10000}>
            <ManualWhitelisting authenticity_token={this.props.authenticity_token}  
            hideBuySellBox={this.state.hideBuySellBox} 
            vipAccessPrice={this.state.vipAccessPrice} 
            contract={this.state.contract} 
            account={this.state.account}  
            vipwhitelistingon={this.props.vipwhitelistingon}
            updateAccount={this.updateAccount} 
            dispatch={this.props.dispatch} 
            S3_ADDRESS={this.props.S3_ADDRESS}  
          />
          </ToastProvider>


        </RecoilRoot>
      </React.Fragment>
    );
  }
}
export default ManualWhitelistPage
