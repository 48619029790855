import React from 'react';
import { useToasts } from 'react-toast-notifications';


// Toast Wrapper for React component class
const WithToast = (Component) => {
  return function WrappedComponent(props) {
      const toastFuncs = useToasts()
      return <Component {...props} {...toastFuncs} />;
  }
}



export default WithToast;